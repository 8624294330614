.color-active.theme-apply {
  color: #23BD73;
}

.link-theme-apply {
  color: #FABB2D;
}

.link-theme-apply.color-inherit {
  color: inherit;
}

.link-theme-apply:hover {
  color: #fcdb91;
}

.link-theme-apply:active {
  color: #fcdb91;
}

.theme-apply.btn {
  background: #FABB2D;
}

.theme-apply.btn:hover {
  background-color: #fbc346;
}

.theme-apply.btn.active, .theme-apply.btn:active {
  background-color: #fbc346;
}

.theme-apply.btn[disabled], .theme-apply.btn.disabled {
  background: #FABB2D;
}

.theme-apply.btn--transparent {
  background: transparent;
  color: inherit !important;
}

.theme-apply.btn--transparent:hover {
  color: inherit !important;
}

.theme-apply.btn--transparent.active, .theme-apply.btn--transparent:active {
  color: inherit !important;
}

.theme-apply.btn--transparent[disabled], .theme-apply.btn--transparent.disabled {
  color: inherit !important;
  background: transparent;
}

.theme-apply.btn--hover-underline:hover .btn__underline {
  border-bottom-color: currentColor;
}

.theme-apply.btn--hover-underline.active .btn__underline, .theme-apply.btn--hover-underline:active .btn__underline {
  border-bottom-color: currentColor;
}

.theme-apply.btn--hover-underline-active:hover .btn__underline {
  border-bottom-color: rgba(250, 187, 45, 0.5);
}

.theme-apply.btn--hover-underline-active.active .btn__underline, .theme-apply.btn--hover-underline-active:active .btn__underline {
  border-bottom-color: rgba(250, 187, 45, 0.5);
}

.theme-apply.btn--hover-transparent:hover {
  background-color: transparent;
}

.theme-apply.btn--hover-transparent.active, .theme-apply.btn--hover-transparent:active {
  background-color: transparent;
}

.theme-apply.btn--hover-light:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.theme-apply.btn--hover-light.active, .theme-apply.btn--hover-light:active {
  background-color: rgba(255, 255, 255, 0.2);
}

.theme-apply.btn--hover-dark:hover {
  background-color: rgba(41, 41, 41, 0.2);
}

.theme-apply.btn--hover-dark.active, .theme-apply.btn--hover-dark:active {
  background-color: rgba(41, 41, 41, 0.2);
}

.theme-apply.btn--white:hover {
  color: #FABB2D !important;
}

.theme-apply.btn--white.active, .theme-apply.btn--white:active {
  color: #FABB2D !important;
}

.theme-apply.btn--grey-dark {
  background-color: #757575;
}

.theme-apply.btn--grey-dark:hover {
  background-color: #A8A8A8;
}

.theme-apply.btn--grey-dark.active, .theme-apply.btn--grey-dark:active {
  background-color: #A8A8A8;
}

.theme-apply.btn--grey-dark[disabled], .theme-apply.btn--grey-dark.disabled {
  background-color: #757575;
}

.theme-apply.btn--gradient {
  background: linear-gradient(270deg, #FF8226 0%, #FEC70E 62%, #FFEF00 125%);
}

.theme-apply.btn--gradient:before {
  background: #FABB2D;
}

.theme-apply.btn--gradient-reverse {
  background: linear-gradient(90deg, #FF8226 0%, #FEC70E 62%, #FFEF00 125%);
}

.theme-apply.btn--outline {
  background-color: transparent;
}

.theme-apply.btn--outline:before {
  border-color: #FABB2D;
}

.theme-apply.btn--outline:hover {
  background-color: #FABB2D;
  color: #fff !important;
}

.theme-apply.btn--outline.active, .theme-apply.btn--outline:active {
  background-color: #FABB2D;
  color: #fff !important;
}

.theme-apply.btn--outline-active {
  color: #FABB2D !important;
}

.theme-apply.btn--outline-gradient {
  background: linear-gradient(270deg, #FF8226 0%, #FEC70E 62%, #FFEF00 125%);
}

.theme-apply.btn--outline-gradient:before {
  border-color: transparent;
}

.theme-apply.btn--color-active {
  color: #FABB2D !important;
}

.theme-apply.btn--color-active:hover {
  color: #FABB2D !important;
}

.theme-apply.btn--color-active.active, .theme-apply.btn--color-active:active {
  color: #FABB2D !important;
}

.theme-apply.btn--checkbox:not(.checked) {
  color: #A8A8A8 !important;
  background: #fff;
}

.theme-apply.btn--checkbox:not(.checked):before {
  border-color: #dedede;
  background: #fff;
}

.theme-apply.btn--checkbox:not(.checked):hover {
  color: #757575 !important;
}

.theme-apply.btn--checkbox:not(.checked):hover:before {
  border-color: #A8A8A8;
}

.theme-apply.btn--checkbox:not(.checked):active {
  color: #757575 !important;
}

.theme-apply.btn--checkbox:not(.checked):active:before {
  border-color: #A8A8A8;
}

.theme-apply.btn--rating {
  background-color: #fff;
}

.theme-apply.btn--rating:hover {
  background-color: #fdfdfd;
}

.theme-apply.btn--rating.active, .theme-apply.btn--rating:active {
  background-color: #fdfdfd;
}

.theme-apply.btn--rating.checked {
  background-color: #fff;
  color: #FABB2D !important;
}

@media (max-width: 1023px) {
  .header__main:before {
    background: linear-gradient(270deg, #FF8226 0%, #FEC70E 62%, #FFEF00 125%);
  }
}

.header-replace {
  height: 202px;
}

@media (max-width: 1023px) {
  .header-replace {
    height: 122px;
  }
}

.hmenu__item.active .hmenu__link {
  color: #FABB2D;
}

.hmenu__link {
  height: 70px;
}

.hmenu__link:hover {
  color: #FABB2D;
}

.hmenu__link-active {
  color: #FABB2D;
}

.htbmenu__link:before {
  background-color: #FABB2D;
}

.htbmenu__link:hover {
  color: #FABB2D;
}

.htbmenu__link:active {
  color: #FABB2D;
}

.hsocial:hover {
  color: #FABB2D;
}

.hsocial:active {
  color: #FABB2D;
}

a.hicon:hover {
  color: #FABB2D;
}

.hlang:hover .hlang__toggler {
  color: #FABB2D;
}

.hlang.active .hlang__toggler {
  color: #FABB2D;
}

.hd-menu__link:hover {
  color: #FABB2D;
}

.hd-menu__link:active {
  color: #FABB2D;
}

.hbbar:before {
  background: linear-gradient(270deg, #FF8226 0%, #FEC70E 62%, #FFEF00 125%);
}

.hsmenu__link:hover {
  color: #FABB2D;
}

.hsmenu__link:active {
  color: #FABB2D;
}

.hs2menu__link:hover {
  color: #FABB2D;
}

.hs2menu__link:active {
  color: #FABB2D;
}

.hs2menu__link-value {
  background-color: #FABB2D;
}

.menu-overlay {
  background-color: #FABB2D;
  background: url("../img/noise.png"), linear-gradient(270deg, #FF8226 0%, #FEC70E 62%, #FFEF00 125%);
}

.menu-overlay .hs2menu__link-value {
  color: #FABB2D;
}

.fmenu__title a:hover {
  color: #FABB2D;
}

.fmenu__title a:active {
  color: #FABB2D;
}

.sfooter-menu a:hover {
  color: #FABB2D;
}

.sfooter-menu a:active {
  color: #FABB2D;
}

a.flogo--default:hover {
  color: #FABB2D !important;
}

a.flogo--default:hover .flogo__icon {
  color: #FABB2D;
}

a.flogo--default.flogo--active-on-hover:hover .flogo-active-on-hover {
  color: #FABB2D;
}

a.atgb__inner:hover .atgb__title {
  color: #FABB2D;
}

.atgb__inner.hover .atgb__title a {
  color: #FABB2D;
}

.atgb__image:before {
  background: linear-gradient(270deg, #FF8226 0%, #FEC70E 62%, #FFEF00 125%);
}

.atgb__title a:hover {
  color: #FABB2D;
}

.atgb__title a:active {
  color: #FABB2D;
}

.atgb--bg a.itag__inner:hover {
  color: #FABB2D !important;
}

.atgb--bg a.itag__inner:active {
  color: #FABB2D !important;
}

a.itag__inner:hover {
  background: rgba(250, 187, 45, 0.15);
  color: #FABB2D !important;
}

a.itag__inner:active {
  background: rgba(250, 187, 45, 0.15);
  color: #FABB2D !important;
}

.itag--dropdown a.itag__inner:hover {
  color: #FABB2D !important;
  box-shadow: inset 0 0 0 1px #FABB2D;
}

.itag--dropdown a.itag__inner:active {
  color: #FABB2D !important;
  box-shadow: inset 0 0 0 1px #FABB2D;
}

.itag--bordered a.itag__inner:hover {
  color: #FABB2D !important;
  box-shadow: inset 0 0 0 1px #FABB2D;
}

.itag--bordered a.itag__inner:active {
  color: #FABB2D !important;
  box-shadow: inset 0 0 0 1px #FABB2D;
}

.itag-menu__item:hover {
  color: #FABB2D !important;
}

.itag-menu__item:active {
  color: #FABB2D !important;
}

.icategory > a {
  color: #FABB2D;
}

.icategory > a:hover {
  color: #FABB2D;
}

.icategory > a:active {
  color: #FABB2D;
}

.icategories--tree .icategory.active > a {
  color: #FABB2D;
}

.icategories--tree .icategory > a:hover {
  color: #FABB2D;
}

.icategories--tree .icategory > a:active {
  color: #FABB2D;
}

@media (max-width: 1259px) {
  .icategories--t-dash .icategory > a:hover {
    color: #FABB2D;
  }
  .icategories--t-dash .icategory > a:active {
    color: #FABB2D;
  }
}

@media (max-width: 767px) {
  .icategories--m-dash .icategory > a:hover {
    color: #FABB2D;
  }
  .icategories--m-dash .icategory > a:active {
    color: #FABB2D;
  }
}

.iscategory.active {
  color: #FABB2D;
}

.iscategory.active > a {
  color: #FABB2D;
}

.iscategory > a:hover {
  color: #FABB2D;
}

.iscategory > a:active {
  color: #FABB2D;
}

.gradient-text.theme-apply {
  color: #FABB2D;
  background-image: -webkit-linear-gradient(180deg, #FF8226 0%, #FEC70E 62%, #FFEF00 125%);
}

.gradient-link.theme-apply {
  color: #FABB2D;
  background-image: -webkit-linear-gradient(180deg, #FF8226 0%, #FEC70E 62%, #FFEF00 125%);
}

.gradient-link.theme-apply:hover {
  background-image: -webkit-linear-gradient(180deg, #FABB2D 0%, #FABB2D 100%);
}

.gradient-link.theme-apply:hover > span {
  border-bottom-color: rgba(250, 187, 45, 0.5);
}

.gradient-icon.theme-apply {
  fill: url("#gradient-active_1") #FABB2D;
}

.sblock--bordered-gradient:before {
  background: linear-gradient(270deg, #FF8226 0%, #FEC70E 62%, #FFEF00 125%);
}

.sblock__title:before {
  background: linear-gradient(270deg, #FF8226 0%, #FEC70E 62%, #FFEF00 125%);
}

a.ptgb__inner:hover .ptgb__title {
  color: #FABB2D;
}

.ptgb__image:before {
  background-image: linear-gradient(270deg, #FF8226 0%, #FEC70E 62%, #FFEF00 125%);
}

a.cmtgb__content:hover .cmtgb__summary {
  color: #FABB2D;
}

.cmtgb__avatar {
  background: linear-gradient(270deg, #FF8226 0%, #FEC70E 62%, #FFEF00 125%);
}

a.cmtgb__more:hover .cmtgb__more-content {
  color: #FABB2D;
}

a.rel-tgb__inner:hover .rel-tgb__title {
  color: #FABB2D;
}

.rel-tgb__inner.hover .rel-tgb__title a {
  color: #FABB2D;
}

.rel-tgb__image:before {
  background: linear-gradient(270deg, #FF8226 0%, #FEC70E 62%, #FFEF00 125%);
}

.icomment__meta-avatar {
  background: linear-gradient(270deg, #FF8226 0%, #FEC70E 62%, #FFEF00 125%);
}

.icomment__action-link:hover {
  color: #FABB2D;
}

.icomment__action-link:active {
  color: #FABB2D;
}

.btn--comment-new:hover {
  color: #FABB2D !important;
}

.btn--comment-new.active, .btn--comment-new:active {
  color: #FABB2D !important;
}

a.auser:hover {
  color: #FABB2D;
}

a.auser:active {
  color: #FABB2D;
}

.auser__avatar {
  background: linear-gradient(270deg, #FF8226 0%, #FEC70E 62%, #FFEF00 125%);
}

.widget-share__title {
  color: #FABB2D;
}

.widget-share__title:after {
  background: #FABB2D;
}

.novashare-button {
  color: #FABB2D;
}

a.novashare-button:hover {
  color: #fbc346;
}

a.novashare-button:active {
  color: #fbc346;
}

.typography a {
  color: #FABB2D;
}

.typography a:hover {
  border-color: #FABB2D;
}

.typography a:active {
  border-color: #FABB2D;
}

.a-s-block:before {
  background: linear-gradient(270deg, #FF8226 0%, #FEC70E 62%, #FFEF00 125%);
}

.imessage {
  border-top-color: #FABB2D;
}

.trating__number {
  background-color: #FABB2D;
}

.trating__toggler:hover {
  color: #FABB2D;
}

.trating__toggler:active {
  color: #FABB2D;
}

/*# sourceMappingURL=style-gardener.css.map */
